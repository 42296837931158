.heady{
  position: fixed;
  z-index: 2;
  font-size: 15px;
  left: 15px;
  top: 0px;
}

.headright img {
  max-width: 40px;
}

.headright:hover{
cursor: pointer;
}

.header-slide{
  border-top: solid black 1px;
  width: calc(100% + 40%);
  display: flex;
  position: relative;
  max-width: 100vw;
  margin: auto;
  left: -20%;
  }

.header-slide h2{
  width: 34%;
  top: 50px;
  height: max-content;
  color: grey;
}

h2{
  margin-top: 200px;
}

.copy {
 width: calc(100% - 40px);
max-width: 600px;
padding: 150px;
margin-right: auto;
counter-reset: section;
}

.source-span {
  display: inline;
}

.copy p img {
  width: 100%;
  filter: grayscale(1);
  transition: .3s;
  padding: 40px 0px 40px 0px;
}

.copy p img:hover {
  filter: grayscale(0);
  transition: .3s;
}

strong:after {
  font-family: 'Courier New', Courier, monospace;
  font-size: .7em;
  font-weight: 600;
  line-height: 1.2;
  counter-increment: section;
  color: var(--link);
  content: "[" counter(section) "]";
  vertical-align: super;
}

strong, p a {
  color: var(--copy);
  text-decoration: none;
  font-weight: 500;
  border-bottom: 1px solid var(--link);
  transition: .3s;
}


p a:hover {
  color: var(--link);
  transition: .3s;
}

a { outline: none; }

p a:after {
  font-family: 'Courier New', Courier, monospace;
  font-size: .7em;
  font-weight: 600;
  line-height: 1.2;
  counter-increment: section;
  color: var(--link);
  content: "[" counter(section) "]";
  vertical-align: super;
}

.source {
  font-family: 'Courier New', Courier, monospace;
  font-size: .7em;
  font-weight: 600;
  line-height: 1.2;
  position: absolute;
  display: block;
  color: var(--link);
  right: 100px;
  max-width: 20%;
  margin-top: -25px;
  display: inline;
 padding-top: 15px;
 border-top: 1px solid var(--link);
}

.source a {
  color: var(--link);
  transition: .4s;
  text-decoration: none;
}

.source a:hover {
  transition: .4s;
  color: var(--copy);
}

.big img {
  filter: grayscale(1);
  position: absolute;
object-fit: cover;
width: 100%;
height: 100%;
}

.sub {
  font-size: .5em;
  color: grey;
}

.big {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.big h1 {
  max-width: 700px;
  z-index: 3;
  color: var(--link);
  margin-top: 20vh;
}

/* Wide */
@media only screen and (min-width: 1600px) {

  .big h1 {
    font-size: 4em;
    max-width: 1000px;
  }

  .copy p {
    font-size: 1.2em;
    line-height: 1.2em;
  }

  .copy {
   padding: 20px;
   margin: auto;
   max-width: 800px;
   }

   .source {
    max-width: 280px;
    right: calc(40% - 550px);
  }
}

/* DEVICE */
@media only screen and (max-width: 1000px) {

  p {
    font-size: .7em;
    line-height: 1.2em;
  }

  .big h1 {
   font-size: 2em;
   padding:40px;
  }


  .copy p img {
    width: 100%;
    margin: 5px;
  }

  .copy {
    max-width: 700px;
   padding: 20px;
   margin: auto;
   }

   .source {
     margin: 50px 0px 50px 0px;
     padding: 30px;
    font-size: .7em;
    line-height: 1.3;
    position: relative;
    display: block;
    color: var(--highlight);
    left: 0px;
    max-width: calc(100% - 60px);
    border: .5px solid var(--link);
    border-radius: 5px;

  }
} 