.ncbutton {
    color: var(--link);
  }
  
  .nc {
    display: flex;
  }
  
  .nc p{
    font-size: 15px;
  }
  
  .city-legend {
    border: 1px solid var(--copy);
    background-color: var(--bg);
    max-width: 200px;
    left: 0vw;
    bottom: 100px;
    margin: 15px 15px 15px 15px;
    padding: 10px;
    z-index: 1;
    position: absolute;
    border-radius: 5px;
    height: max-content;
    font-size: .8em;
    line-height: 1.2em;
  }
  
  .city-legend p{
    margin-bottom: 10px;
    font-size: .7em;
  }
  
  .city-legend h2{
    padding-top: 0px;
    margin: 0px;
  }
  
  .city-legend-dot {
    height: 12px;
    width: 12px;
    margin-right: 6px;
    display: inline-block;
  }
  
  .options-toggle {
    border: 1px solid var(--copy);
    background-color: var(--bg);
    margin: 15px 0px 10px 15px;
    z-index: 1;
    left: 0;
    bottom: 50px;
    position: absolute;
    display: inline-flex;
    text-align: center;
    border-radius: 6px;
  }
  
  input {
    display: none;
  }
  
  input:checked + .options-high {
    background: var(--copy);
    color: var(--bg);
  }
  
  .options-contain {
    display: block;
    max-width: 100%;
  }
  
  .options-high {
    border: 1px solid var(--bg);
    font-size: .7em;
    color: var(--copy);
    flex-shrink: 0;
    cursor: pointer;
    padding: 0 12px;
    background-color: transparent;
    transition: color .125s,background-color .125s;
    padding: 0px 5px 0px 5px;
    border-radius: 5px;
  }
  
  .city-map {
    display: flex;
    background: var(--bg);
    min-width:80vw;
    max-height: 100vh;
  }

  .home-bttn {
    padding: 0px 5px 0px 5px;
    border: 1px solid var(--copy);
    background-color: var(--bg);
    margin: 15px 0px 10px 15px;
    z-index: 1;
    position: absolute;
    border-radius: 6px;
    font-size: .8em;
  }

  .home-bttn a {
    color: var(--link);
  }

  
  .nc-list {
    width: 20vw;
    top:0;
    position: sticky;
    display: flex;
    border-left: 1px solid grey;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none;
  
    scrollbar-width: none;
    background-color: var(--bg);
  }
  
  .nc-list:-webkit-scrollbar {
    display: none;
  }
  
  .map-container {
  min-width: 40vw;
  min-height: calc(100vh - 20px);
  margin: 10px;
  border-radius: 18px;
  }
  
  .city-map-container {
    min-width: 100%;
    min-height: 100%;
  }
  
  .nc-cell a {
    color: var(--link);
  }
  
  .nc-cell {
    border-bottom: grey 1px solid;
    font-size: .7em;
    padding: 5px;
    width: 100%;
  }
  
  .nc-select {
    display: flex;
    background: var(--bg);
    width:80vw;
    top: 0px;
    height: 100vh;
  }
  
  .nc-select-info {
    padding: 20px 30px 0px 30px;
    width: 100%;
    height: calc(100vh - 20px);
    overflow:scroll;
  }
  
  .nc-select-info table {
    width: 100%;
    padding: 0px;
    margin: 10px 0px 0px 0px;
    font-size: .7em;
  }
  
  .nc-select-info h2 {
    font-size: 1.8em;
    margin: 15px 0px 10px 0px;
  }
  
  .nc-select-info .rank {
    color: var(--link);
    font-size: .7em;
    margin-left: 20px;
    width: 80px;
  }

  .nc-select-info td .tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    background: var(--copy);
    padding: 10px 15px 15px 15px;
    border-radius: 0px 0px 10px 10px;
    /* border: solid 1px grey; */
    /* margin-left: 13%; */
    margin-top: 7px;
    color: var(--bg);
    font-size: .9em;
    line-height: 1.3em;
    max-width: 250px;
    /* box-shadow: 0px 2px 5px grey; */
  }

  .nc-select-info td:hover .tooltip {
  visibility: visible;
  }

  .nc-select-info td:focus .tooltip {
    visibility: visible;
    }

  .wafflechart {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .wafflechart div{
    height: 30px;
    transition: .5s;
    border-radius: 6px;
    border: 1px solid var(--bg);
  }
  
  .wafflekey {
    margin-bottom: 15px;
    font-size: .8em;
  }
  
  .wafflekey p {
    font-weight: 400;
    margin: 0px;
    color: var(--copy);
  
  }
  
  .parkedkey {
    display: flex;
  }
  
  .parkedkey p {
    margin-right: 30px;
    line-height: 26px;
  }
  
  .parkkey:before{
    background-color: var(--park);
  }
  
  .sidekey:before{
    background-color: var(--walk);
  }
  
  .roadkey:before{
    background-color: var(--road);
  }
  
  .shedkey:before{
    background-color: #b6c569;
  }
  
  .wafflekey p:before{
    display: inline-block;
    content:"";
    width: 30px;
    height: 30px;
    margin: 10px 5px -10px 0px;
    border-radius: 50%;
  }
  
  .nc-select-info a:hover {
    color:var(--copy);
    transition: .3s;
  }
  
  .nc-select-info th {
    text-align: left;
  }
  
  
  .nc-select-info td {
    color: grey;
    border-top: grey solid 1px;
    padding: 4px 1px 4px 5px;
    font-size: .9em;
    line-height: 1.8em;
  }
  
  .nc-select-info .data {
  width: 30%;
  color: var(--copy);
  }

  .nc-select-info a {
    color: var(--link);
    text-decoration: none;
    font-size: .8em;
    transition: .3s;
  }

  
  .mapboxgl-popup-content {
    color: black;
    padding: 10px;
    border-radius: 10px;
    background: var(--bg);
    color: var(--copy);
  }


  .mapboxgl-popup-content .desc {
    color: grey;
    padding-right: 12px;
  }
  
  .mapboxgl-popup-content a {
    color: var(--link);
    font-weight: bold;
    border: 1px solid var(--link);
    padding: 2px 7px 4px 7px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 5px;
    text-decoration: none;
  }

  /* Wide */
@media only screen and (min-width: 1600px ) {

    .nc-cell {
        font-size: .8em;
        padding: 10px;
      }
}

/* tablet */
@media only screen and (min-width: 700px) and (max-width: 1200px ) {

    .nc {
        display: block;
        width: 70%;
      }
    

    .nc-cell {
        font-size: .8em;
        padding: 10px;
      }


      .nc-select {
        display: block;
        width: calc(100% - 10px);
        font-size: .8em;
        height: 100%;
      }

      .nc-select-info {
        padding: 0px;
        margin: 20px;
        width: calc(100% - 30px);
        height: 100%;
        }

        .mapboxgl-canvas-container {
            height: 40vh;
            border-radius: 15px;
        }


  
.city-map {
    min-width: 100%;
    min-height: 100vh;
  }
  
  .map-container {
    min-width: calc(100% - 30px);
    border-radius: 15px;
    margin: 20px;
    min-height: 100%;
  }


  .nc-list {
    position: fixed;
    right: 0;
    width: calc(30% - 5px)
  }
}
  
/* DEVICE */
@media only screen and (max-width: 700px) {
    .nc {
        display: block;
      }
    
      .nc-select {
        display: block;
        width: calc(100% - 10px);
        font-size: .8em;
        height: 100%;
      }
    
      .nc-select-info {
      padding: 0px;
      margin: 20px;
      width: calc(100% - 10px);
      height: 100%;
      }
    
      .nc-list {
        width: 100%;
        display: block;
        max-height: 60vh;
        background: var(--bg);
        border: none;
      }
    
      .nc-cell {
        border-bottom: grey solid 1px;
        font-size: .6em;
        width: calc(100% - 26px);
        padding-left: 20px;
        background: var(--copy);
      }

    
  .nc-cell span {
    color: var(--bg);
  }

  .nc-select-info table {
    border: none;
    padding: 0px;
    width: calc(100% - 20px);
  }

  .waffle {
    border: none;
    padding: 0px;
    width: calc(100% - 20px);
  }

  .nc-select-info h2 {
    font-size: 1.4em;
    margin-left: 0px;
  }

  .city-map{
    width: 100%;
    height: 78vh;
  }

  .home-bttn {
    font-size: .6em;
  }


  .options-high {
    font-size: .45em;
  }

  .city-legend {
    line-height: 1.4em;
    font-size: .6em;
    bottom: 30vh;
    max-width:100px;
  }

  .options-toggle {
    bottom: 25vh;
  }


.shedkey{
  display: none;
}

  
.city-map-container {
  min-width: 100%;
  min-height: 78vh;
}

.map-container {
  min-width: calc(100% - 30px);
  margin: 20px;
  min-height: 100%;
}

.mapboxgl-map {
    max-height: 40vh;
}

  .mapboxgl-canvas-container {
    height: 40vh;
}
} 