:root {
  --bg: rgb(12, 12, 12);
  --copy: rgb(235, 235, 235);
  --link:rgb(235, 99, 20);
  --hover:red;
  --park:#18b818;
  --road:#e95757;
  --walk:#eeb72d;
}

body {
  background: var(--bg);
  color: var(--copy);
  font-family: acumin-pro, sans-serif; 
  font-weight: 500;
  font-style: normal;
  font-size: 1.5em;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  margin: auto;
  /* transition: .5s; */
}

h1 {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 700;
  letter-spacing: 0.015em;
  font-size: 2.8em;
  color: black ;
  line-height: 1.1;
  text-align: center;
  margin: auto;
}

h2 {
  line-height: 1.2;
}

hr {
  margin-top: 60px;
  margin-bottom: 60px;
  border: none;
  border-bottom: solid 1px grey;
}


code {
  font-family: source-code-pro,  'Courier New', monospace;
  font-weight: 600;
  font-size: .8em;
}

.mapContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 50%;
  height: 100vh;
  left: 50%;
  }

  .mapboxgl-ctrl-bottom-right {
    font-size: 10px;
    text-align: right;
    
  }